/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { MEDIA_LIBRARY_UPLOAD } from "./routes";
import { navigateFn } from "../../utils/routes.utils";
import * as actions from "./actions";
import MediaActionBar from "pages/media-library/components/action-bar/action-bar";
import Inspector from "components/inspector/inspector";
import { FixedTabList } from "components/tabs/tabs";
import ImageUpload from "./components/image-upload/image-upload";
import wrapPage from "../../containers/page/page";
import MediaGallery from "./components/media-gallery/media-gallery";
import StoryList from "./components/story-list/story-list";
import {
  saveMediaAction,
  closeInspectorAction,
  setSelectedMediaAction,
  updateSelectedTabAction,
  loadStoriesAction
} from "./action-creators";
import { t } from "i18n";
import { ThunkDispatch } from "redux-thunk";
import { ImageError, PartialAppState } from "./state";
import styles from "./media-library.module.css";
import { Image as ImageType, ImageOrNewImage } from "api/search-media-image";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "./components/loader/loader";
import { MediaStats } from "api/media-stats";
import { LoaderState } from "behaviors/loader/state";
import { Location } from "history";

interface MediaLibraryDispatchProps {
  navigate(): void;
  switchToUploadRoute(mediaKey: string): void;
  setSelectedMedia(media: ImageOrNewImage[]): void;
  updateImageUploadStatus(status: { uploading: boolean }): void;
  updateSelectedMediaData(key: string, value: any, mediaIndex: number): void;
  updateError(value: ImageError[]): void;
  saveMedia(): void;
  closeInspector(): void;
  updateSelectedTab(tab: string): void;
  loadMoreStories(location: Location): void;
}

interface MediaLibraryStateProps {
  isSidebarActive: boolean;
  selectedMedia: ImageType[];
  uploading: boolean;
  isSaving: boolean;
  errors: ImageError[];
  enableEXIFLocation: boolean;
  selectedTab: string;
  pathParams: any;
  mediaStats: MediaStats;
  loader: LoaderState;
  isLinkAuthorEnabled: boolean;
  isStoryImageHyperlinkEnabled: boolean;
}

interface MediaLibraryOwnProps {
  showMediaLibraryInspector: boolean;
  showFields: boolean;
  match: any;
}

type MediaLibraryProps = MediaLibraryDispatchProps & MediaLibraryStateProps & MediaLibraryOwnProps;

const MediaLibrary: React.SFC<MediaLibraryProps> = (props) => (
  <React.Fragment>
    <div className={styles["page-container media-library"]}>
      <MediaGallery
        switchToUploadRoute={props.switchToUploadRoute}
        setSelectedMedia={props.setSelectedMedia}
        updateImageUploadStatus={props.updateImageUploadStatus}
        enableMultipleUploads={true}
        showEditImage={true}
      />
    </div>
    <Inspector
      title={t("mediaLibrary.media_properties")}
      actionButtonLabel={t("mediaLibrary.save")}
      onActionButtonClick={() => props.saveMedia()}
      isActive={props.showMediaLibraryInspector}
      onClose={() => props.closeInspector()}
      isActionButtonDisabled={props.isSaving}
      showActionButton={props.selectedTab === "STORIES" ? false : true}
      variant="wide">
      {props.pathParams !== "new" && (
        <div className={styles["inspector-tabs"]}>
          <FixedTabList
            tabs={[
              { label: t("mediaLibrary.media_details"), value: "DETAILS" },
              { label: t("mediaLibrary.usage_information"), value: "STORIES" }
            ]}
            value={props.selectedTab}
            onChange={props.updateSelectedTab}
          />
        </div>
      )}
      {(props.selectedTab === "DETAILS" || props.pathParams === "new") && (
        <ImageUpload
          multipleSelected={props.selectedMedia.length > 1}
          selectedImages={props.selectedMedia}
          isUploading={props.uploading}
          setSelectedMedia={props.setSelectedMedia}
          updateError={props.updateError}
          updateImageData={props.updateSelectedMediaData}
          onDeleteMedia={props.closeInspector}
          errors={props.errors}
          showFields={props.showFields}
          isLinkAuthorEnabled={true}
          enableEXIFLocation={props.enableEXIFLocation}
          isStoryImageHyperlinkEnabled={props.isStoryImageHyperlinkEnabled}
        />
      )}
      {props.selectedTab === "STORIES" && (
        <LoaderWrapper loader={props.loader} component={Loader}>
          <StoryList
            mediaStats={props.mediaStats}
            loadMoreStories={props.loadMoreStories}
            selectedMedia={props.selectedMedia}
          />
        </LoaderWrapper>
      )}
    </Inspector>
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState, ownProps: MediaLibraryOwnProps) => {
  return {
    isSidebarActive: state.mediaLibrary.isSidebarActive,
    selectedMedia: state.mediaLibrary.selectedMedia,
    uploading: state.mediaLibrary.uploading,
    isSaving: state.mediaLibrary.ui.isSaving,
    errors: state.mediaLibrary.errors,
    enableEXIFLocation: state.features.enableEXIFLocation,
    selectedTab: state.mediaLibrary.ui.selectedTab,
    pathParams: ownProps.match.params.mediaKey,
    mediaStats: state.mediaLibrary.mediaStats,
    loader: state.mediaLibrary.ui.main,
    isStoryImageHyperlinkEnabled: state.features.enableStoryImageHyperlink
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    switchToUploadRoute: (mediaKey: string) => navigate(MEDIA_LIBRARY_UPLOAD, { mediaKey }),
    setSelectedMedia: (media: ImageOrNewImage[]) => dispatch(setSelectedMediaAction(media)),
    updateImageUploadStatus: (status: { uploading: boolean }) =>
      dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_IMAGE_UPLOAD_STATUS, payload: status }),
    updateSelectedMediaData: (key: string, value: any, mediaIndex: number) =>
      dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_SELECTED_MEDIA_DATA, payload: { key, value, mediaIndex } }),
    updateError: (value: ImageError[]) => dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_ERROR, payload: value }),
    saveMedia: () => dispatch(saveMediaAction()),
    closeInspector: () => dispatch(closeInspectorAction()),
    updateSelectedTab: (tab: string) => dispatch(updateSelectedTabAction(tab, navigate)),
    loadMoreStories: (location: Location) => dispatch(loadStoriesAction(location))
  };
};

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ ActionBarComponent: MediaActionBar })
)(MediaLibrary);
