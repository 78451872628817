/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const PUSH_NOTIFICATION_INDEX_PATH = "/content/push-notifications";
export const PUSH_NOTIFICATION_NEW_PATH = "/content/push-notifications/new";
export const PUSH_NOTIFICATION_NEW_PUBLISH_PATH = "/content/push-notifications/new/publish";
export const PUSH_NOTIFICATION_EDIT_PATH = "/content/push-notifications/:id";
export const PUSH_NOTIFICATION_EDIT_PUBLISH_PATH = "/content/push-notifications/:id/publish";
export const PUSH_NOTIFICATION_ALL_PATH = "/content/push-notifications/all";
export const PUSH_NOTIFICATION_PUBLISHED_PATH = "/content/push-notifications/published";
export const PUSH_NOTIFICATION_SCHEDULED_PATH = "/content/push-notifications/scheduled";
