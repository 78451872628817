/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch, Redirect } from "react-router";

import PushNotification from "./push-notification";
import {
  PUSH_NOTIFICATION_NEW_PATH,
  PUSH_NOTIFICATION_INDEX_PATH,
  PUSH_NOTIFICATION_EDIT_PATH,
  PUSH_NOTIFICATION_NEW_PUBLISH_PATH,
  PUSH_NOTIFICATION_EDIT_PUBLISH_PATH,
  PUSH_NOTIFICATION_SCHEDULED_PATH,
  PUSH_NOTIFICATION_PUBLISHED_PATH,
  PUSH_NOTIFICATION_ALL_PATH
} from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Redirect exact from={PUSH_NOTIFICATION_INDEX_PATH} to={PUSH_NOTIFICATION_ALL_PATH} />
      <Route exact path={PUSH_NOTIFICATION_ALL_PATH} component={PushNotification} />
      <Route exact path={PUSH_NOTIFICATION_SCHEDULED_PATH} component={PushNotification} />
      <Route exact path={PUSH_NOTIFICATION_PUBLISHED_PATH} component={PushNotification} />
      <Route exact path={PUSH_NOTIFICATION_NEW_PATH} component={PushNotification} />
      <Route exact path={PUSH_NOTIFICATION_NEW_PUBLISH_PATH} component={PushNotification} />
      <Route exact path={PUSH_NOTIFICATION_EDIT_PATH} component={PushNotification} />
      <Route exact path={PUSH_NOTIFICATION_EDIT_PUBLISH_PATH} component={PushNotification} />
    </Switch>
  </React.Fragment>
);
