/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Preview = () => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="24" height="24" viewBox="0 0 24 24" fill="#5f6978">
    <path d="M20.73 12a1.39 1.39 0 0 1 0 1.5 10.14 10.14 0 0 1-8.78 5 9.9 9.9 0 0 1-5.12-1.4 10 10 0 0 1-3.66-3.63 1.39 1.39 0 0 1 0-1.5 9.93 9.93 0 0 1 3.66-3.62A9.91 9.91 0 0 1 12 7a10 10 0 0 1 5.13 1.38 9.9 9.9 0 0 1 3.6 3.62zm-.84 1a.45.45 0 0 0 0-.5 8.7 8.7 0 0 0-1.72-2.11 9.61 9.61 0 0 0-2.28-1.51 4.61 4.61 0 0 1 1.06 3 4.67 4.67 0 0 1-.67 2.44 5.05 5.05 0 0 1-8.66 0A4.76 4.76 0 0 1 7 11.85a4.61 4.61 0 0 1 1-3 9.11 9.11 0 0 0-2.28 1.53A9 9 0 0 0 4 12.47.45.45 0 0 0 4 13a9 9 0 0 0 3.35 3.28A9 9 0 0 0 12 17.47a9.14 9.14 0 0 0 4.59-1.2 8.78 8.78 0 0 0 3.3-3.27zM16 11.85a3.83 3.83 0 0 0-2-3.36A4.13 4.13 0 0 0 12 8a4.07 4.07 0 0 0-2 .52A3.8 3.8 0 0 0 8.48 9.9a3.85 3.85 0 0 0 0 3.9 3.88 3.88 0 0 0 1.46 1.41 4.06 4.06 0 0 0 2 .51 4.12 4.12 0 0 0 2-.51 3.85 3.85 0 0 0 1.45-1.41 3.76 3.76 0 0 0 .61-1.95zm-6-.13a2 2 0 0 0 3.42 1.43A2 2 0 0 0 11 10a.82.82 0 0 1 .82.82.75.75 0 0 1-.24.57.77.77 0 0 1-.58.24.82.82 0 0 1-.81-.81 1.8 1.8 0 0 0-.19.9z" />
  </svg>
);

export default Preview;
