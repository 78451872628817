/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { TranslationFunction } from "i18next";

export enum TabValue {
  SCHEDULED = "@@push_notification_tab/SCHEDULED",
  PUBLISHED = "@@push_notification_tab/PUBLISHED",
  ALL = "@@push_notification_tab/ALL"
}

export interface Tab {
  label: any;
  value: TabValue;
}

const TAB_LIST = (t: TranslationFunction): Tab[] => [
  {
    label: t("push-notification.tabs.all"),
    value: TabValue.ALL
  },
  {
    label: t("push-notification.tabs.scheduled"),
    value: TabValue.SCHEDULED
  },
  {
    label: t("push-notification.tabs.published"),
    value: TabValue.PUBLISHED
  }
];

export const getStringTabValue = (tab: TabValue): string => {
  switch (tab) {
    case TabValue.SCHEDULED:
      return "scheduled";
    case TabValue.PUBLISHED:
      return "published";
    case TabValue.ALL:
      return "all";
    default:
      return "scheduled";
  }
};

export default TAB_LIST;
