/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import { MEDIA_LIBRARY_PATH, MEDIA_LIBRARY_UPLOAD, MEDIA_LIBRARY_STORIES } from "./routes";

import MediaLibrary from "./media-library";

export const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={MEDIA_LIBRARY_PATH} render={(props) => <MediaLibrary {...props} title="media_library" />} />

        <Route
          exact
          path={MEDIA_LIBRARY_UPLOAD}
          render={(props) => <MediaLibrary {...props} showMediaLibraryInspector={true} title="media_library" />}
        />
        <Route
          exact
          path={MEDIA_LIBRARY_STORIES}
          render={(props) => <MediaLibrary {...props} showMediaLibraryInspector={true} title="media_library" />}
        />
      </Switch>
    </React.Fragment>
  );
};
