/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { PartialAppState } from "../../state";
import Button from "components/button/button";
import { navigateToInspector } from "../../async-action-creators";
import { t } from "i18n";
import Header from "components/header/header";

import { AnyPushNotification } from "api/push-notification";

interface ownProps {
  currentPushNotification: AnyPushNotification;
}

type headerProps = ownProps & DispatchProps;

export class PushNotificationHeader extends React.Component<headerProps> {
  render() {
    const { navigateToInspector, currentPushNotification } = this.props;
    return (
      <React.Fragment>
        <Header>
          {currentPushNotification && (
            <Button
              key={"Publish"}
              type="primary"
              variant="success"
              onClick={() => navigateToInspector(currentPushNotification)}>
              {t("push-notification.cta.publish")}
            </Button>
          )}
        </Header>
      </React.Fragment>
    );
  }
}

interface DispatchProps {
  navigateToInspector: (currentPushNotification: AnyPushNotification) => void;
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    currentPushNotification: state.pushNotification.app.currentPushNotification
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    navigateToInspector: (currentPushNotification: AnyPushNotification) =>
      dispatch(navigateToInspector(currentPushNotification))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PushNotificationHeader);
