/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Inspector from "components/inspector/inspector";
import { FixedTabList, Panel } from "components/tabs/tabs";
import { t } from "i18n";
import Settings, { CollectionTab } from "pages/collections/components/settings/settings";
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { navigateFn } from "utils/routes.utils";
import { COLLECTIONS_SELECTED_COLLECTION_PATH } from "../../routes";
import { updateCollectionAction } from "../../async-action-creators";
import { PartialAppState } from "pages/collections/initial-state";
import { actions } from "../../actions";
import { CollectionId } from "api/primitive-types";
import { ThunkDispatch } from "redux-thunk";
import { AnyCollection } from "api/story-collection";
import { isExistingCollection } from "../../utils";

interface StateProps {
  stagingSelectedCollection: AnyCollection;
}

interface DispatchProps {
  closeSettingsInspector: (collectionId: CollectionId | "new") => void;
  setSelectedTab: (tab: string, collectionId: CollectionId | "new") => void;
  updateCollection: () => void;
}

interface InspectorProps {
  selectedTab: CollectionTab;
  isActive: boolean;
}

export const fetchTabList = (collection: AnyCollection) => {
  const collectionList = [
    {
      label: t("collections.tabs.content"),
      value: "content"
    },
    {
      label: t("collections.tabs.metadata"),
      value: "metadata"
    },
    {
      label: t("collections.tabs.rules"),
      value: "rules"
    }
  ];
  return collection.template === "breaking-news"
    ? collectionList.filter((tab) => tab.value !== "rules")
    : collectionList;
};

type Props = StateProps & DispatchProps & InspectorProps;

const ContentInspector: React.SFC<Props> = ({
  closeSettingsInspector,
  selectedTab,
  setSelectedTab,
  isActive,
  stagingSelectedCollection,
  updateCollection
}) => (
  <Inspector
    title={t("collections.settings")}
    actionButtonLabel={t("collections.update")}
    onActionButtonClick={updateCollection}
    isActive={isActive}
    onClose={() =>
      isExistingCollection(stagingSelectedCollection)
        ? closeSettingsInspector(stagingSelectedCollection.id)
        : closeSettingsInspector("new")
    }
    level={0}>
    <React.Fragment>
      <FixedTabList
        tabs={fetchTabList(stagingSelectedCollection)}
        value={selectedTab}
        onChange={(tab) =>
          isExistingCollection(stagingSelectedCollection)
            ? setSelectedTab(tab, stagingSelectedCollection.id)
            : setSelectedTab(tab, "new")
        }
      />
      <Panel>
        <Settings selectedTab={selectedTab} />
      </Panel>
    </React.Fragment>
  </Inspector>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    stagingSelectedCollection: state.collections.collectionPage.stagingSelectedCollection!
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, any>): DispatchProps => {
  const navigate = navigateFn(dispatch);

  return {
    closeSettingsInspector: (collectionId: CollectionId) => {
      dispatch({ type: actions.RESET_ERRORS });
      dispatch({ type: actions.RESET_STAGING_SELECTED_COLLECTION });
      navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collectionId || "new" });
    },
    setSelectedTab: (tab: string, collectionId: CollectionId) => {
      collectionId
        ? navigate(`/content/collections/${collectionId}/settings/${tab}`)
        : navigate(`/content/collections/new/settings/${tab}`);
    },
    updateCollection: () => dispatch(updateCollectionAction())
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContentInspector);
