/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import pDebounce from "p-debounce";
import { hasValidationErrors, ValidationError } from "utils";
import { getMediaLibraryData, saveMedia, uploadExternalMedia } from "../../helpers/api";
import { push } from "connected-react-router";
import { goBack } from "utils/routes.utils";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import * as actions from "./actions";
import uploadImage from "pages/media-library/image-upload";
import { getFileName } from "pages/media-library/image-utils";
import { getImageDimensions, getMetadataWithDimensions } from "utils/image-metadata.utils";
import { MEDIA_LIBRARY_PATH, MEDIA_LIBRARY_UPLOAD, MEDIA_LIBRARY_STORIES } from "./routes";
import { mapForRequest, mapResponseForExternal } from "../../helpers/image-keys-mapping";
import { NOTIFICATION_ERROR } from "containers/page/actions";
import { t } from "i18n";
import { fetchMediaStats } from "../../api/media-stats";
import startofmonth from "date-fns/startOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import { hasValidationError, sanitizeFileName } from "pages/story-editor/async-action-creators";

const debounceGetMediaLibraryData = pDebounce(getMediaLibraryData, 500);

const convertDateToUTC = (dateString) => Date.parse(dateString);

const getDateFilterTS = (dateFilter) => {
  let today = new Date().setHours(0, 0, 0, 0);
  let now = Date.now();

  switch (dateFilter.type) {
    case "today": {
      return [today, now];
    }
    case "this-week": {
      return [convertDateToUTC(startOfWeek(today)), now];
    }
    case "this-month": {
      return [convertDateToUTC(startofmonth(today)), now];
    }
    case "custom": {
      return [dateFilter.from, dateFilter.to];
    }
    default:
      return null;
  }
};

export const loadMediaDataAction = (options: any = {}) => (dispatch) => {
  const {
    attribution = null,
    author = {},
    "geo-radius": geoRadius = null,
    limit = null,
    location = {},
    offset = null,
    searchTerm = "",
    "task-id": taskId = null,
    "uploaded-at": uploadedAt = {},
    source = null
  } = options;
  const dateRangeFilters = getDateFilterTS(uploadedAt);
  const { lat, lon } = location;
  const locationParam = lat && lon && [lat, lon].join(",");

  dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_SEARCH_TERM, payload: { searchTerm } });
  if (!offset || offset === 0) {
    dispatch({ type: actions.MEDIA_LIBRARY_LOADING_STATUS, payload: { loading: true } });
  }

  let queryOptions = {
    operator: "or",
    provider: "all",
    ...(searchTerm && { q: searchTerm }),
    ...(taskId && !searchTerm && { "task-id": taskId }),
    ...(limit && { limit }),
    ...(offset && { offset }),
    ...(attribution && { attribution }),
    ...(author && author.id && { "member-id": author.id }),
    ...(locationParam && { location: locationParam }),
    ...(geoRadius && { "geo-radius": geoRadius + "km" }),
    ...(dateRangeFilters && { "uploaded-after-ts": dateRangeFilters[0] }),
    ...(dateRangeFilters && { "uploaded-before-ts": dateRangeFilters[1] }),
    ...(source && { "post-filter-source": source })
  };

  debounceGetMediaLibraryData(queryOptions).then((data) => {
    if (offset && offset > 0) {
      dispatch({ type: actions.MEDIA_LIBRARY_NEXT_DATA_SUCCESS, payload: { data } });
    } else {
      dispatch({ type: actions.MEDIA_LIBRARY_DATA_SUCCESS, payload: { data } });
    }
    dispatch({ type: actions.MEDIA_LIBRARY_LOADING_STATUS, payload: { loading: false } });
  });
};

export const setMediaOnProviderChangeAction = () => (dispatch, getState) => {
  const currentMediaProvider = getState().mediaLibrary.currentMediaProvider;
  const defaultProvider = { media: [], page: { total: 0 } };
  if (isEmpty(getState().mediaLibrary.providers)) {
    return defaultProvider;
  }
  const providerValue = currentMediaProvider.value;
  return get(getState().mediaLibrary.providers, [providerValue], defaultProvider);
};

export const addNewMediaAction = (
  files,
  options,
  { updateImageUploadStatus, switchToUploadRoute, setSelectedMedia, showEditImage }
) => (dispatch) => {
  if (files.length === 0) {
    return;
  }
  updateImageUploadStatus({ uploading: true });

  if (showEditImage) {
    switchToUploadRoute("new");
  }
  const imageUploadError = () => {
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("mediaLibrary.image_upload_error") } });
  };
  uploadImage(files, updateImageUploadStatus, setSelectedMedia, imageUploadError, options);
};

export const loadStoriesAction = (location) => async (dispatch, getState) => {
  let { stories } = getState().mediaLibrary.mediaStats;
  dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_STORY_LIST_LOADER_STATUS, payload: { loading: true, error: null } });
  let mediaStats = await fetchMediaStats(location, { limit: 20, offset: stories.length, type: "image" });
  dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_MEDIA_STATS, payload: { mediaStats } });
  dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_STORY_LIST_LOADER_STATUS, payload: { loading: false, error: null } });
};

export const updateSelectedTabAction = (tab, navigateFn) => async (dispatch, getState) => {
  let selectedMedia = getState().mediaLibrary.selectedMedia[0];
  let fileName = getFileName(selectedMedia);
  let location = selectedMedia && selectedMedia.key;

  if (tab === "STORIES") {
    navigateFn(MEDIA_LIBRARY_STORIES, { mediaKey: fileName });
    dispatch(loadStoriesAction(location));
  } else {
    navigateFn(MEDIA_LIBRARY_UPLOAD, { mediaKey: fileName });
  }

  dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_SELECTED_TAB, payload: { selectedTab: tab } });
};

export const editMediaAction = (
  media,
  { updateImageUploadStatus, switchToUploadRoute, setSelectedMedia, showEditImage }
) => (dispatch) => {
  const selectedMedia = media.image;
  const mediaInstance = media.instances[0];

  updateImageUploadStatus({ uploading: true });

  if (showEditImage) {
    // When we are showing edit screen in inspector
    dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_SELECTED_TAB, payload: { selectedTab: "DETAILS" } });
    switchToUploadRoute(getFileName(selectedMedia));
  }

  if (!selectedMedia.metadata) {
    getImageDimensions(selectedMedia.url).then((dimensions) => {
      setSelectedMedia([
        {
          ...selectedMedia,
          caption: mediaInstance.caption,
          attribution: mediaInstance.attribution,
          "alt-text": mediaInstance["alt-text"],
          metadata: dimensions,
          index: media.index
        }
      ]);
    });
  } else {
    setSelectedMedia([
      {
        ...selectedMedia,
        caption: mediaInstance.caption,
        attribution: mediaInstance.attribution,
        "alt-text": mediaInstance["alt-text"],
        hyperlink: mediaInstance.hyperlink,
        index: media.index
      }
    ]);
  }

  updateImageUploadStatus({ uploading: false });
};

export const editExternalMediaAction = (
  media,
  { updateImageUploadStatus, switchToUploadRoute, setSelectedMedia, showEditImage }
) => (dispatch) => {
  updateImageUploadStatus({ uploading: true });
  if (showEditImage) {
    switchToUploadRoute("new");
  }
  uploadExternalMedia([media]).then(
    (response) => {
      const imageResponse = mapResponseForExternal(response[0]);
      setSelectedMedia([imageResponse]);
      updateImageUploadStatus({ uploading: false });
    },
    (error) => {
      updateImageUploadStatus({ uploading: false });
      dispatch(goBack());
      dispatch({ type: NOTIFICATION_ERROR, payload: { message: t("mediaLibrary.unable_to_fetch_image") } });
    }
  );
};

export const closeInspectorAction = (resetFilter = false) => (dispatch, getState) => {
  if (resetFilter) {
    dispatch(loadMediaDataAction());
  }
  dispatch(push(MEDIA_LIBRARY_PATH));
  dispatch({ type: actions.MEDIA_LIBRARY_CLEAR_SELECTED_MEDIA });
};

export const saveMediaAction = () => (dispatch, getState) => {
  let hasErrors: Array<ValidationError> = [];
  let mediaLibrary = getState().mediaLibrary;
  dispatch({ type: actions.MEDIA_LIBRARY_IMAGE_SAVE_INIT });
  mediaLibrary.selectedMedia = mediaLibrary.selectedMedia.map((item, index) => {
    const isNewImage = item.hasOwnProperty("temp-image-key");
    if (isNewImage) {
      item.metadata["file-name"] = sanitizeFileName(item.metadata["file-name"] || "");
      hasErrors = [...hasErrors, ...hasValidationError(item, index)];
    }
    return item;
  });
  hasErrors = [...hasErrors, ...hasValidationErrors(mediaLibrary.selectedMedia)];
  if (hasErrors.length > 0) {
    dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_ERROR, payload: hasErrors });
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("mediaLibrary.unable_processing_image", { count: hasErrors.length }) }
    });
  } else {
    dispatch({ type: actions.MEDIA_LIBRARY_REMOVE_ERROR });
    const reqParams = mediaLibrary.selectedMedia.map((item) => mapForRequest(item));
    const isNewMediaAdded = reqParams.some((param) => "temp-image-key" in param);
    saveMedia(reqParams).then(
      (response) => {
        if (!isNewMediaAdded) {
          mediaLibrary.selectedMedia.forEach((media) => {
            let payload: any = {
              index: media.index,
              updates: {
                instances: {
                  caption: media.caption,
                  attribution: media.attribution,
                  hyperlink: media.hyperlink,
                  "alt-text": media["alt-text"]
                },
                image: {
                  location: media["extracted-data"] && media["extracted-data"].location
                }
              }
            };
            dispatch({ type: actions.MEDIA_LIBRARY_UPDATE_MEDIA, payload });
          });
        }
        const payload = { showAdvancedSearch: mediaLibrary.ui.showAdvancedSearch && !isNewMediaAdded };
        dispatch({ type: actions.MEDIA_LIBRARY_IMAGE_SAVE_SUCCESS, payload });
        dispatch(closeInspectorAction(isNewMediaAdded));
      },
      (error) => {
        dispatch({ type: actions.MEDIA_LIBRARY_IMAGE_SAVE_FAILURE, payload: { error } });
      }
    );
  }
};

export const setSelectedMediaAction = (images) => async (dispatch) => {
  const promises = images.map(async (image) => {
    const metadata = await getMetadataWithDimensions(image);
    return { ...image, metadata };
  });
  const imagesWithMetadata = await Promise.all(promises);
  dispatch({ type: actions.MEDIA_LIBRARY_SET_SELECTED_MEDIA, payload: imagesWithMetadata });
};

export const clearMediaForMultiSelectAction = () => (dispatch) => {
  dispatch({ type: actions.MEDIA_LIBRARY_CLEAR_MEDIA_FOR_MULTI_SELECT });
};

export const updateAdvancedSearchOpts = (searchOpts) => (dispatch) => {
  dispatch({
    type: actions.MEDIA_LIBRARY_UPDATE_ADVANCED_SEARCH_OPTIONS,
    payload: searchOpts
  });
};

export const toggleShowAdvancedSearch = () => (dispatch) => {
  dispatch({ type: actions.MEDIA_LIBRARY_TOGGLE_SHOW_ADVANCED_SEARCH });
};
