/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AddNewFile from "components/add-new-file/add-new-file";
import MediaList from "pages/media-library/components/media-list/media-list";
import Media from "components/icons/media";
import { Media as MediaType, ExistingOpts } from "pages/media-library/state";
import NoContentCard from "components/no-content-card/no-content-card";
import { t } from "i18n";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "components/spinner/spinner";
import styles from "./publisher-media.module.css";
import Dropzone from "components/dropzone/dropzone";
import DropzoneMessage, { DropzoneMessageType, DropzoneMessageVariant } from "components/dropzone/dropzone-message";
import { MimeType } from "utils/file.utils";
import { AdvancedSearchOpts } from "pages/media-library/state";

interface PublisherMediaProps {
  totalCount: number;
  searchTerm: string;
  mediaItems: MediaType[];
  mediaForMultiSelect: MediaType[];
  setMediaItemForMultiSelect: (media: MediaType) => void;
  editMedia: (media: MediaType) => void;
  addNewMedia: (files: File[]) => void;
  loadNextData: (opts: ExistingOpts) => void;
  displayError: (error: string) => void;
  scrollableTarget?: string;
  loadDataOptions?: ExistingOpts;
  enableMultipleUploads?: boolean;
  canMultiSelect?: boolean;
  advancedSearchOpts?: AdvancedSearchOpts;
}

const PublisherMedia: React.FC<PublisherMediaProps> = ({
  mediaItems,
  addNewMedia,
  editMedia,
  enableMultipleUploads,
  canMultiSelect = false,
  setMediaItemForMultiSelect,
  mediaForMultiSelect,
  searchTerm,
  loadNextData,
  displayError,
  loadDataOptions = {},
  totalCount,
  scrollableTarget,
  advancedSearchOpts = {}
}) => {
  return (
    <Dropzone
      dropHandler={addNewMedia}
      accept={MimeType.Images}
      onError={displayError}
      enableMultipleUploads={enableMultipleUploads}
      showOnDragAnywhere={true}
      errorMapping={{
        fileType: t("components.dropzone.file-type-error"),
        fileCount: t("components.dropzone.file-count-error"),
        fileSize: t("components.dropzone.file-size-error")
      }}
      dragEnterMessage={
        <DropzoneMessage
          message={enableMultipleUploads ? t("mediaLibrary.on-drag-message-plural") : t("mediaLibrary.on-drag-message")}
          variant={DropzoneMessageVariant.Large}
          type={DropzoneMessageType.Image}
          classname={styles["dropzone-message"]}
        />
      }>
      <InfiniteScroll
        dataLength={mediaItems.length}
        next={() => {
          loadNextData({ ...loadDataOptions, searchTerm, offset: mediaItems.length, limit: 20, ...advancedSearchOpts });
        }}
        scrollableTarget={scrollableTarget}
        hasMore={totalCount - mediaItems.length > 0}
        loader={<Spinner message={t("mediaLibrary.loading")} />}
        endMessage={
          <NoContentCard>
            <Media />
            {t("mediaLibrary.no_more_results_found")}
          </NoContentCard>
        }>
        <div className={styles["media-gallery"]} data-test-id="media-gallery">
          <AddNewFile
            accept={MimeType.Images}
            addNewFile={addNewMedia}
            enableMultipleUploads={enableMultipleUploads}
            onError={displayError}
            showDropzoneOnDragAnywhere={false}
            disableDropzone={true}
          />
          <MediaList
            mediaItems={mediaItems}
            editMedia={editMedia}
            addThumbnailUrl={true}
            canMultiSelect={canMultiSelect}
            setMediaItemForMultiSelect={setMediaItemForMultiSelect}
            mediaForMultiSelect={mediaForMultiSelect}
          />
        </div>
      </InfiniteScroll>
    </Dropzone>
  );
};

export default PublisherMedia;
