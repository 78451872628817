/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { AnyPushNotification, UnsavedPushNotification } from "api/push-notification";
import { PushNotificationId } from "api/primitive-types";
import * as api from "api/push-notification";
import { loadWorkspaceData } from "api/workspace";
import { PushNotificationServerValidationError, PushNotificationNotFoundError } from "./errors";
import { navigate, route } from "utils/routes.utils";
import { pushNotificationtoUnsavedPushNotification, generateWorkspaceRequestBody } from "./utils";
import { toClientValidationError } from "utils/validation.utils";
import {
  PUSH_NOTIFICATION_INDEX_PATH,
  PUSH_NOTIFICATION_EDIT_PATH,
  PUSH_NOTIFICATION_NEW_PATH,
  PUSH_NOTIFICATION_EDIT_PUBLISH_PATH,
  PUSH_NOTIFICATION_NEW_PUBLISH_PATH,
  PUSH_NOTIFICATION_PUBLISHED_PATH,
  PUSH_NOTIFICATION_SCHEDULED_PATH,
  PUSH_NOTIFICATION_ALL_PATH
} from "./routes";
import { validatePushNotification } from "./validate";
import {
  initNewPushNotificationAction,
  savePushNotificationAction,
  savePushNotificationSuccessAction,
  listPushNotifications,
  listPushNotificationsSuccess,
  listPushNotificationsFailure,
  savePushNotificationFailureAction,
  loadPushNotificationAction,
  updatePublishLaterAction,
  loadPushNotificationFailureAction,
  updateCurrentPushNotification,
  cancelPublishAction,
  maybePublishAction,
  updateTabAction,
  updateSearchTermAction
} from "./action-creators";
import { TabValue, getStringTabValue } from "./tab-list";
import pDebounce from "p-debounce";
import { NOTIFICATION_SUCCESS } from "containers/page/actions";
import { t } from "i18n";

export const loadPushNotifications = (term: string, currentTab: TabValue) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(updateCurrentPushNotification(null));
  dispatch(listPushNotifications());
  const pushNotificationStatus = getStringTabValue(currentTab);
  const requestBody = generateWorkspaceRequestBody(term, pushNotificationStatus, 0);
  try {
    const pushNotificationResponse = await loadWorkspaceData(requestBody);
    dispatch(listPushNotificationsSuccess(pushNotificationResponse));
  } catch (error) {
    dispatch(listPushNotificationsFailure(error));
  }
};

export const loadNextPushNotifications = (
  term: string,
  currentTab: TabValue,
  currentPushNotificationList: api.PushNotificationListItem[]
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const pushNotificationStatus = getStringTabValue(currentTab);
  const requestBody = generateWorkspaceRequestBody(term, pushNotificationStatus, currentPushNotificationList.length);
  try {
    const pushNotificationResponse = await loadWorkspaceData(requestBody);
    const newPushNotificationList = currentPushNotificationList.concat(pushNotificationResponse["push-notifications"]);
    dispatch(
      listPushNotificationsSuccess({ ...pushNotificationResponse, "push-notifications": newPushNotificationList })
    );
  } catch (error) {
    dispatch(listPushNotificationsFailure(error));
  }
};

export const initNewPushNotification = (currentPushNotification: AnyPushNotification | null) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  if (!currentPushNotification) {
    dispatch(initNewPushNotificationAction());
  }
};

export const savePushNotification = (
  publishAt: number | null,
  pushNotification: AnyPushNotification,
  channelsEnabled: boolean
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const unsavedPushNotification: UnsavedPushNotification = pushNotificationtoUnsavedPushNotification(
    publishAt,
    pushNotification
  );

  dispatch(savePushNotificationAction());

  // validations
  const validationErrors = validatePushNotification(unsavedPushNotification, channelsEnabled);
  if (validationErrors) {
    dispatch(savePushNotificationFailureAction(toClientValidationError<UnsavedPushNotification>(validationErrors)));
    return;
  }

  // api call
  try {
    await api.publishNewPushNotificationV1(unsavedPushNotification);
    setTimeout(() => {
      dispatch(savePushNotificationSuccessAction());
      dispatch(navigate(publishAt ? PUSH_NOTIFICATION_SCHEDULED_PATH : PUSH_NOTIFICATION_PUBLISHED_PATH));
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: {
          message: publishAt
            ? t("push-notification.messages.successfully-scheduled")
            : t("push-notification.messages.successfully-published")
        }
      });
    }, 500);
  } catch (error) {
    const errorJson = JSON.parse(error.message);
    if (errorJson.status === 422) {
      dispatch(
        savePushNotificationFailureAction(new PushNotificationServerValidationError(error.message, errorJson.error))
      );
    } else {
      dispatch(savePushNotificationFailureAction(error));
    }
  }
};

export const loadPushNotification = (
  currentPushNotification: AnyPushNotification | null,
  id: PushNotificationId
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  if (
    !currentPushNotification ||
    !currentPushNotification["notification-id"] ||
    /* eslint-disable */
    // Need != instead of !== for the check below because id is a string and notification id is a number
    currentPushNotification["notification-id"] != id
    /* eslint-enable */
  ) {
    dispatch(savePushNotificationAction());
    try {
      const response = await api.loadPushNotification(id);
      const isPublishLater: boolean = response["publish-at"] ? true : false;
      dispatch(loadPushNotificationAction(response));
      dispatch(updatePublishLaterAction(isPublishLater));
    } catch (error) {
      const errorJson = JSON.parse(error.message);
      if (errorJson.status === 404) {
        dispatch(loadPushNotificationFailureAction(new PushNotificationNotFoundError(id, error.message)));
        dispatch(navigate(PUSH_NOTIFICATION_INDEX_PATH));
        dispatch({
          type: NOTIFICATION_SUCCESS,
          payload: { message: t("push-notification.messages.already-published") }
        });
      } else {
        dispatch(loadPushNotificationFailureAction(error));
      }
    }
  }
};

export const updatePushNotification = (
  publishAt: number | null,
  pushNotification: AnyPushNotification,
  channelsEnabled: boolean
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  const unsavedPushNotification: UnsavedPushNotification = pushNotificationtoUnsavedPushNotification(
    publishAt,
    pushNotification
  );
  dispatch(savePushNotificationAction());
  const validationErrors = validatePushNotification(unsavedPushNotification, channelsEnabled);
  if (validationErrors) {
    dispatch(savePushNotificationFailureAction(toClientValidationError<UnsavedPushNotification>(validationErrors)));
    return;
  }
  dispatch(updateCurrentPushNotification(unsavedPushNotification));

  try {
    const pushNotificationId = pushNotification["notification-id"];
    await api.updatePushNotificationV1(pushNotificationId, unsavedPushNotification);

    setTimeout(() => {
      dispatch(savePushNotificationSuccessAction());
      dispatch(navigate(publishAt ? PUSH_NOTIFICATION_SCHEDULED_PATH : PUSH_NOTIFICATION_PUBLISHED_PATH));
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: {
          message: publishAt
            ? t("push-notification.messages.successfully-rescheduled")
            : t("push-notification.messages.successfully-updated")
        }
      });
    }, 500);
  } catch (error) {
    const errorJson = JSON.parse(error.message);
    if (errorJson.status === 422 && errorJson.error) {
      dispatch(
        savePushNotificationFailureAction(new PushNotificationServerValidationError(error.message, errorJson.error))
      );
    } else {
      dispatch(savePushNotificationFailureAction(error));
    }
  }
};

export const navigateToInspector = (currentPushNotification: AnyPushNotification | null) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const inspectorPath =
    currentPushNotification && currentPushNotification["notification-id"]
      ? route(PUSH_NOTIFICATION_EDIT_PUBLISH_PATH, { id: currentPushNotification["notification-id"] })
      : PUSH_NOTIFICATION_NEW_PUBLISH_PATH;
  dispatch(navigate(inspectorPath));
};

export const navigateBackFromInspector = (currentPushNotification: AnyPushNotification | null) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const formPath =
    currentPushNotification && currentPushNotification["notification-id"]
      ? route(PUSH_NOTIFICATION_EDIT_PATH, { id: currentPushNotification["notification-id"] })
      : PUSH_NOTIFICATION_NEW_PATH;
  dispatch(navigate(formPath));
};

export const cancelPublish = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelPublishAction());
};

export const maybePublish = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(maybePublishAction());
};

export const updateTab = (tab: TabValue) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateTabAction(tab));
};

export const updateSearchTerm = (term: string, tab: TabValue) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateSearchTermAction(term));
  dispatch(pDebounce(loadPushNotifications(term, tab), 250));
};

export const savePushNotificationFailure = (error: Error) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(savePushNotificationFailureAction(error));
};

export const navigateToTab = (tabValue: TabValue) => (dispatch: ThunkDispatch<any, any, any>) => {
  switch (tabValue) {
    case TabValue.PUBLISHED:
      dispatch(navigate(PUSH_NOTIFICATION_PUBLISHED_PATH));
      break;
    case TabValue.SCHEDULED:
      dispatch(navigate(PUSH_NOTIFICATION_SCHEDULED_PATH));
      break;
    case TabValue.ALL:
      dispatch(navigate(PUSH_NOTIFICATION_ALL_PATH));
      break;
  }
};
