/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { AnyPushNotification, UnsavedPushNotification } from "api/push-notification";
import Inspector from "components/inspector/inspector";
import DatePickerCustom from "components/date-picker/date-picker";
import { t } from "i18n";
import RadioButton from "components/radio-button/radio-button";
import { format as formatDate, isAfter } from "date-fns";
import styles from "./inspector.module.css";
import { getErrorMessage } from "../../utils";
import { PushNotificationDateValidationError } from "../../errors";

interface State {
  isPublishLater: boolean;
  publishAt: number | null;
}

interface Props {
  currentPushNotification: AnyPushNotification;
  channelsEnabled?: boolean;
  isInspectorActive: boolean;
  error: Error | null;
  navigateBackFromInspector: (currentPushNotification: AnyPushNotification) => void;
  saveNewPushNotification: (
    publishAt: number | null,
    pushNotification: AnyPushNotification,
    channelsEnabled: boolean
  ) => void;
  saveNewPushNotificationFailure: (error: Error) => void;
  updatePushNotification: (
    publishAt: number | null,
    pushNotification: AnyPushNotification,
    channelsEnabled: boolean
  ) => void;
  updateCurrentPushNotification: (pushNotification: UnsavedPushNotification) => void;
}

export class PushNotificationInspector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isPublishLater: props.currentPushNotification && props.currentPushNotification["publish-at"] ? true : false,
      publishAt:
        props.currentPushNotification && props.currentPushNotification["publish-at"]
          ? props.currentPushNotification && props.currentPushNotification["publish-at"]
          : null
    };

    this.updateCurrentPublishAt = this.updateCurrentPublishAt.bind(this);
    this.onChangePublishLater = this.onChangePublishLater.bind(this);
    this.publishPushNotification = this.publishPushNotification.bind(this);
  }

  updateCurrentPublishAt(publishAt: number | null) {
    this.setState({
      publishAt
    });
  }

  onChangePublishLater(isPublishLater: boolean) {
    this.setState({
      isPublishLater
    });
  }

  validateDateField(): boolean {
    if (this.state.isPublishLater) {
      if (this.state.publishAt == null) {
        this.props.saveNewPushNotificationFailure(
          new PushNotificationDateValidationError(t("push-notification.messages.publish-date-missing"))
        );
        return false;
      } else if (isAfter(new Date(), this.state.publishAt)) {
        this.props.saveNewPushNotificationFailure(
          new PushNotificationDateValidationError(t("push-notification.messages.publish-date-invalid"))
        );
        return false;
      }
    }
    return true;
  }

  publishPushNotification() {
    if (this.validateDateField()) {
      const publishAt = this.state.isPublishLater ? this.state.publishAt : null;
      this.props.currentPushNotification["notification-id"]
        ? this.props.updatePushNotification(publishAt, this.props.currentPushNotification, this.props.channelsEnabled!)
        : this.props.saveNewPushNotification(
            publishAt,
            this.props.currentPushNotification,
            this.props.channelsEnabled!
          );
    }
  }

  render() {
    return (
      <Inspector
        title={t("push-notification.inspector.publish-inspector-title")}
        onClose={() => this.props.navigateBackFromInspector(this.props.currentPushNotification)}
        isActive={this.props.isInspectorActive}
        actionButtonLabel={t("push-notification.cta.publish")}
        onActionButtonClick={() => {
          this.publishPushNotification();
        }}>
        <div className={styles["push-notification-inspector-radio-group"]}>
          <RadioButton
            id="radio-publish-now"
            label={t("common.publish-now")}
            checked={!this.state.isPublishLater}
            name="publish"
            onChange={(isPublishNow: boolean) => this.onChangePublishLater(!isPublishNow)}
            classname={"push-notification-inspector-radio-button"}
          />
          <RadioButton
            id="radio-publish-later"
            label={t("common.publish-later")}
            checked={this.state.isPublishLater}
            name="publish"
            onChange={(isPublishLater: boolean) => this.onChangePublishLater(isPublishLater)}
            classname={"push-notification-inspector-radio-button"}
          />
        </div>
        {this.state.isPublishLater && (
          <div>
            <h3>{t("push-notification.inspector.schedule-message")}</h3>
            {this.props.currentPushNotification["publish-at"] && (
              <p>
                {t("push-notification.inspector.already-scheduled-message") +
                  formatDate(this.props.currentPushNotification["publish-at"], "dd/MM/yyyy hh:mm a")}
              </p>
            )}
            {this.props.currentPushNotification && (
              <div>
                <DatePickerCustom
                  showTimeSelect
                  onChange={this.updateCurrentPublishAt}
                  selectedDate={this.state.publishAt}
                />
                <div className={styles["push-notification-targets-error-message"]}>
                  {getErrorMessage("publish-at", this.props.error)}
                </div>
              </div>
            )}
          </div>
        )}
      </Inspector>
    );
  }
}
export default PushNotificationInspector;
