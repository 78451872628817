/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";
import Collections from "./collections";
import Dashboard from "./dashboard/collections-dashboard";
import {
  COLLECTIONS_ALL_ITEMS_PATH,
  COLLECTIONS_CONTENT_IMAGE_EDIT_PATH,
  COLLECTIONS_CONTENT_MEDIA_GALLERY_PATH,
  COLLECTIONS_CONTENT_SETTINGS_PATH,
  COLLECTIONS_DELETE_PATH,
  COLLECTIONS_METADATA_SETTINGS_PATH,
  COLLECTIONS_PATH,
  COLLECTIONS_PUBLISH_PATH,
  COLLECTIONS_RULES_SETTINGS_PATH,
  COLLECTIONS_SELECTED_COLLECTION_PATH,
  COLLECTIONS_TABS_PATH
} from "./routes";

export const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={COLLECTIONS_PATH} component={Dashboard} />
        <Route exact path={COLLECTIONS_TABS_PATH} component={Dashboard} />
        <Route
          exact
          path={COLLECTIONS_PUBLISH_PATH}
          render={(props) => <Collections {...props} inspector="publish" title="collections" />}
        />
        <Route
          exact
          path={COLLECTIONS_SELECTED_COLLECTION_PATH}
          render={(props) => <Collections {...props} title="collections" />}
        />
        <Route
          exact
          path={COLLECTIONS_ALL_ITEMS_PATH}
          render={(props) => <Collections {...props} inspector="all-items" title="collections" />}
        />
        <Route
          exact
          path={COLLECTIONS_CONTENT_SETTINGS_PATH}
          render={(props) => {
            return <Collections {...props} inspector="content" inspectorTab="content" title="collections" />;
          }}
        />
        <Route
          exact
          path={COLLECTIONS_CONTENT_MEDIA_GALLERY_PATH}
          render={(props) => <Collections {...props} title="collections" inspector="image" />}
        />
        <Route
          exact
          path={COLLECTIONS_CONTENT_MEDIA_GALLERY_PATH}
          render={(props) => <Collections {...props} title="collections" inspector="image" />}
        />

        <Route
          exact
          path={COLLECTIONS_CONTENT_IMAGE_EDIT_PATH}
          render={(props) => <Collections {...props} title="collections" inspector="image-edit" />}
        />

        <Route
          exact
          path={COLLECTIONS_CONTENT_IMAGE_EDIT_PATH}
          render={(props) => <Collections {...props} title="collections" inspector="image-edit" />}
        />

        <Route
          exact
          path={COLLECTIONS_METADATA_SETTINGS_PATH}
          render={(props) => <Collections {...props} inspectorTab="metadata" title="collections" inspector="content" />}
        />
        <Route
          exact
          path={COLLECTIONS_RULES_SETTINGS_PATH}
          render={(props) => <Collections {...props} inspectorTab="rules" title="collections" inspector="content" />}
        />
        <Route
          exact
          path={COLLECTIONS_DELETE_PATH}
          render={(props) => <Collections {...props} showCollectionDeleteModal={true} title="Collection" />}
        />
      </Switch>
    </React.Fragment>
  );
};
