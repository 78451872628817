/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { NavLink as ReactRouterNavLink, NavLinkProps as ReactRouterNavLinkProps } from "react-router-dom";
import { match as Match } from "react-router";
import { Location } from "history";
import Home from "components/icons/home";
import Analytics from "components/icons/analytics";
import Receipt from "components/icons/receipt";
import Manage from "components/icons/manage";
import Media from "components/icons/media";
import Pages from "components/icons/pages";
import Settings from "components/icons/settings";
import UserCircle from "components/icons/user-circle";
import { UserFriends } from "@quintype/em/icons/user-friends";

import { MEDIA_LIBRARY_PATH, SETTINGS_PATH, USER_PATH } from "../../routes";

import styles from "./navbar.module.css";
import { Features } from "api/route-data/route-data";
import classnames from "classnames/bind";
import { t } from "i18n";

import Tooltip from "components/tooltip/tooltip";
import Quintype from "components/icons/quintype";
import OutsideClickHandler from "components/outside-click-handler/outside-click-handler";
import { isEqual as _isEqual } from "lodash";
import ContentSidebar from "pages/content/sidebar/sidebar";
import ManageSidebar from "pages/manage/sidebar/sidebar";
import AnalyticsSidebar from "pages/analytics/components/sidebar";
import SettingsSidebar from "pages/settings/sidebar/sidebar";
import UsersAndRolesSidebar from "pages/settings/sidebar/users-and-roles-sidebar";
import ConfigureSidebar from "pages/settings/sidebar/configure-sidebar";
import UserSidebar from "pages/user/sidebar/sidebar";
import WorkspaceSidebar from "pages/workspace/components/sidebar/sidebar";
import MediaLibrarySidebar from "pages/media-library/components/sidebar/sidebar";
import CollectionsSidebar from "pages/collections/components/sidebar/sidebar";
import MenuSidebar from "pages/manage/menu/components/sidebar/sidebar";
import StoryEditorSidebar from "pages/story-editor/components/sidebar/sidebar";
import { ANALYTICS_INDEX_PATH } from "pages/analytics/routes";
import { MANAGE_PATH } from "pages/manage/routes";
import { CONTENT_PATH } from "pages/content/routes";
import { WORKSPACE_PATH } from "pages/workspace/routes";
import { CONSUMERS_PATH } from "pages/consumers-dashboard/routes";
import { BILLING_PATH } from "pages/billing/routes";
import WithGAEvent from "helpers/ga-analytics/with-ga-event";
import BillingSidebar from "pages/billing/components/billing/sidebar";

const cx = classnames.bind(styles);

interface NavLinkProps extends ReactRouterNavLinkProps {
  disabled?: boolean;
  disabledClassName?: string;
}

export const NavLink: React.SFC<NavLinkProps> = ({ disabled, children, ...navLinkProps }) => {
  const props = disabled
    ? {
        ...navLinkProps,
        to: {},
        isActive: () => true,
        className: cx(navLinkProps.className, "navbar-link-disabled")
      }
    : navLinkProps;
  return <ReactRouterNavLink {...props}>{children}</ReactRouterNavLink>;
};

interface Props {
  features: Features;
  isBannerPresent: boolean;
  location: Location;
  match: Match;
  navigate: (path: string, params?: { [key: string]: number | string | boolean }) => void;
  renderInDesktopView: boolean;
}

interface State {
  currentActiveSidebar: string | null;
  isInExpandedState: boolean;
}

const generateSidebarItems = (features: Features) => {
  let items = { workspace: "workspace" };
  items = {
    ...items,
    ...{ analytics: "analytics" },
    ...{ general: "settings" },
    ...{ user: "user" },
    ...{ story: "story-editor" },
    ...(features.enableCollectionsInSidebar && { collections: "collections" }),
    ...(features.enablePushNotificationInSidebar && { "push-notifications": "content" }),
    ...(features.enableStaticPageInSidebar && { "static-pages": "content" }),
    ...(features.enableMediaLibraryIconInNavBar && { "media-library": "mediaLibrary" }),
    ...(features.enableAdminLinksInSidebar && {
      sections: "manage",
      menu: "menu",
      attributes: "manage",
      "custom-urls": "manage",
      "social-sharing": "settings",
      integrations: "settings",
      "users-and-roles": "usersAndRoles",
      configure: "configure"
    }),
    ...(features.enableTagsInSidebar && { tags: "manage" }),
    ...(features.enableEntitiesInSidebar && { entities: "manage" })
  };
  return items;
};

const setInitialSidebar = (path: string, features: Features) => {
  const pathString = path.split("/");
  for (let i = pathString.length; i > 0; i--) {
    if (generateSidebarItems(features)[pathString[i]]) {
      return generateSidebarItems(features)[pathString[i]];
    }
  }
};

class Navbar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentActiveSidebar: "",
      isInExpandedState: false
    };
  }

  collapse = () => {
    this.setState({ isInExpandedState: false });
  };

  toggleExpanded = () => {
    this.setState({
      isInExpandedState: !this.state.isInExpandedState,
      currentActiveSidebar: setInitialSidebar(this.props.match.path, this.props.features)
    });
  };

  setActiveSidebar = (sidebar: string) => {
    this.setState({ currentActiveSidebar: sidebar, isInExpandedState: true });
  };

  switchToWorkspace = (e: React.MouseEvent) => {
    e.preventDefault();
    this.collapse();
    this.props.navigate("/workspace/all");
  };

  switchToMediaLibrary = (e: React.MouseEvent) => {
    e.preventDefault();
    this.collapse();
    this.props.navigate(MEDIA_LIBRARY_PATH);
  };

  switchToConsumerDashboard = (e: React.MouseEvent) => {
    e.preventDefault();
    this.collapse();
    this.props.navigate(CONSUMERS_PATH);
  };

  componentDidMount() {
    this.setState({ currentActiveSidebar: setInitialSidebar(this.props.match.path, this.props.features) });
  }

  componentDidUpdate(prevProps: Props) {
    if (!_isEqual(this.props.features, prevProps.features)) {
      this.setState({ currentActiveSidebar: setInitialSidebar(this.props.match.path, this.props.features) });
    }
  }

  isMenuSelected = (path: string, name: string) => {
    // If not expanded, then check if menu name is present in route path
    if (!this.state.isInExpandedState) {
      return this.props.location.pathname.startsWith(path);
    } else {
      // If expanded, match for menu name based on the assumption that nested menus are separated by hyphens
      // For example, settings -> configure is represented as 'setting-configure'
      if (`${this.state.currentActiveSidebar}`.split("-").includes(name)) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { features, isBannerPresent, renderInDesktopView, match } = this.props;
    const navbarWrapperClass = cx("navbar-wrapper", {
      "with-banner": isBannerPresent,
      "is-expanded": this.state.isInExpandedState
    });

    return (
      <OutsideClickHandler onOutsideClick={this.collapse}>
        <WithGAEvent label="header-quintype-logo">
          {renderInDesktopView ? (
            <div className={cx("quintype-logo", { "with-banner": isBannerPresent })}>
              <NavLink to="/" data-test-id="quintype-logo" aria-label="Home">
                <Quintype />
              </NavLink>
            </div>
          ) : (
            <button
              className={cx("quintype-logo", { "with-banner": isBannerPresent })}
              onClick={this.toggleExpanded}
              data-test-id="mobile-quintype-logo">
              <Quintype />
            </button>
          )}
        </WithGAEvent>
        <nav className={navbarWrapperClass}>
          <div className={styles["navbar-container"]}>
            <ul className={styles["navbar"]} data-test-id="navbar-container-ul">
              <li className={styles["navbar-item"]} data-test-id="navbar-link-workspace" data-for="workspace" data-tip>
                <div
                  data-test-id="navbar-link-workspace-btn"
                  className={cx("navbar-link", { "is-selected": this.isMenuSelected(WORKSPACE_PATH, "workspace") })}
                  onClick={(e) => this.switchToWorkspace(e)}>
                  <Home />
                </div>
                <Tooltip id="workspace" place="right" effect="solid" value={t("navbar.workspace")} />
              </li>
              {features.enableContentIconInNavBar && (
                <li className={styles["navbar-item"]} data-test-id="navbar-link-content" data-for="content" data-tip>
                  <div
                    data-test-id="navbar-link-content-btn"
                    className={cx("navbar-link", { "is-selected": this.isMenuSelected(CONTENT_PATH, "content") })}
                    onClick={(e) => this.setActiveSidebar("content")}>
                    <Pages />
                  </div>
                  <Tooltip id="content" place="right" effect="solid" value={t("navbar.content")} />
                </li>
              )}
              {features.enableMediaLibraryIconInNavBar && (
                <li
                  className={styles["navbar-item"]}
                  data-test-id="navbar-link-media-gallery"
                  data-for="media-gallery"
                  data-tip>
                  <div
                    data-test-id="navbar-link-media-gallery-btn"
                    className={cx("navbar-link", {
                      "is-selected": this.isMenuSelected(MEDIA_LIBRARY_PATH, "mediaGalary")
                    })}
                    onClick={(e) => this.switchToMediaLibrary(e)}>
                    <Media />
                  </div>
                  <Tooltip id="media-gallery" place="right" effect="solid" value={t("navbar.media_gallery")} />
                </li>
              )}
              {features.enableManageIconInNavBar && (
                <li className={styles["navbar-item"]} data-test-id="navbar-link-manage" data-for="manage" data-tip>
                  <div
                    data-test-id="navbar-link-manage-btn"
                    className={cx("navbar-link", { "is-selected": this.isMenuSelected(MANAGE_PATH, "manage") })}
                    onClick={(e) => this.setActiveSidebar("manage")}>
                    <Manage />
                  </div>
                  <Tooltip id="manage" place="right" effect="solid" value={t("navbar.manage")} />
                </li>
              )}
              {features.enableAnalyticsInNavBar && (
                <li
                  className={styles["navbar-item"]}
                  data-test-id="navbar-link-analytics"
                  data-for="analytics"
                  data-tip>
                  <div
                    data-test-id="navbar-link-analytics-btn"
                    className={cx("navbar-link", {
                      "is-selected": this.isMenuSelected(ANALYTICS_INDEX_PATH, "analytics")
                    })}
                    onClick={(e) => this.setActiveSidebar("analytics")}>
                    <Analytics />
                  </div>
                  <Tooltip id="analytics" place="right" effect="solid" value={t("navbar.analytics")} />
                </li>
              )}
              {features.enableBillingIconInNavBar && (
                <li className={styles["navbar-item"]} data-test-id="navbar-link-billing" data-for="billing" data-tip>
                  <div
                    data-test-id="navbar-link-billing-btn"
                    className={cx("navbar-link", {
                      "is-selected": this.isMenuSelected(BILLING_PATH, "billing")
                    })}
                    onClick={(e) => this.setActiveSidebar("billing")}>
                    <Receipt />
                  </div>
                  <Tooltip id="billing" place="right" effect="solid" value={t("navbar.billing")} />
                </li>
              )}
              {features.enableSettingsIconInNavBar && (
                <li className={styles["navbar-item"]} data-test-id="navbar-link-settings" data-for="settings" data-tip>
                  <div
                    data-test-id="navbar-link-settings-btn"
                    className={cx("navbar-link", { "is-selected": this.isMenuSelected(SETTINGS_PATH, "settings") })}
                    onClick={(e) => this.setActiveSidebar("settings")}>
                    <Settings />
                  </div>
                  <Tooltip id="settings" place="right" effect="solid" value={t("navbar.settings")} />
                </li>
              )}
              {features.enableConsumerDashboardIconInNavBar && (
                <li
                  className={styles["navbar-item"]}
                  data-test-id="navbar-link-consumers"
                  data-for="consumers"
                  data-tip>
                  <div
                    data-test-id="navbar-link-consumers-btn"
                    className={cx("navbar-link", { "is-selected": this.isMenuSelected(CONSUMERS_PATH, "consumers") })}
                    onClick={this.switchToConsumerDashboard}>
                    <UserFriends label="" />
                  </div>
                  <Tooltip id="consumers" place="right" effect="solid" value={t("navbar.consumers")} />
                </li>
              )}
              <li className={styles["navbar-item"]} data-test-id="navbar-link-profile" data-for="my-profile" data-tip>
                <div
                  data-test-id="navbar-link-profile-btn"
                  className={cx("navbar-link", { "is-selected": this.isMenuSelected(USER_PATH, "user") })}
                  onClick={(e) => this.setActiveSidebar("user")}>
                  <UserCircle />
                </div>
                <Tooltip id="my-profile" place="right" effect="solid" value={t("navbar.my_profile")} />
              </li>
            </ul>
          </div>
        </nav>
        {this.state.isInExpandedState && (
          <div className="sidebar-wrapper">
            {this.state.currentActiveSidebar === "content" && (
              <ContentSidebar
                isBannerPresent={isBannerPresent}
                match={match}
                setActiveSidebar={(type) => this.setActiveSidebar(type)}
              />
            )}
            {this.state.currentActiveSidebar === "manage" && (
              <ManageSidebar
                isBannerPresent={isBannerPresent}
                match={match}
                setActiveSidebar={(type) => this.setActiveSidebar(type)}
              />
            )}
            {this.state.currentActiveSidebar === "analytics" && (
              <AnalyticsSidebar match={match} isBannerPresent={isBannerPresent} />
            )}
            {this.state.currentActiveSidebar === "billing" && (
              <BillingSidebar
                isBannerPresent={isBannerPresent}
                match={match}
                setActiveSidebar={(type) => this.setActiveSidebar(type)}
              />
            )}
            {this.state.currentActiveSidebar === "settings" && (
              <SettingsSidebar
                isBannerPresent={isBannerPresent}
                match={match}
                setActiveSidebar={(type) => this.setActiveSidebar(type)}
              />
            )}
            {this.state.currentActiveSidebar === "settings-usersAndRoles" && (
              <UsersAndRolesSidebar
                isBannerPresent={isBannerPresent}
                match={match}
                setActiveSidebar={(type) => this.setActiveSidebar(type)}
              />
            )}
            {this.state.currentActiveSidebar === "settings-configure" && (
              <ConfigureSidebar
                match={match}
                isBannerPresent={isBannerPresent}
                setActiveSidebar={(type) => this.setActiveSidebar(type)}
              />
            )}
            {this.state.currentActiveSidebar === "user" && (
              <UserSidebar match={match} isBannerPresent={isBannerPresent} />
            )}
            {this.state.currentActiveSidebar === "workspace" && (
              <WorkspaceSidebar isBannerPresent={isBannerPresent} isDesktopSizeViewport={renderInDesktopView} />
            )}
            {this.state.currentActiveSidebar === "mediaLibrary" && (
              <MediaLibrarySidebar isBannerPresent={isBannerPresent} isDesktopSizeViewport={renderInDesktopView} />
            )}
            {this.state.currentActiveSidebar === "collections" && (
              <CollectionsSidebar isBannerPresent={isBannerPresent} isDesktopSizeViewport={renderInDesktopView} />
            )}
            {this.state.currentActiveSidebar === "menu" && (
              <MenuSidebar isBannerPresent={isBannerPresent} isDesktopSizeViewport={renderInDesktopView} />
            )}
            {this.state.currentActiveSidebar === "story-editor" && (
              <StoryEditorSidebar isBannerPresent={isBannerPresent} isDesktopSizeViewport={renderInDesktopView} />
            )}
          </div>
        )}
      </OutsideClickHandler>
    );
  }
}

export default Navbar;
