/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import buildSearchQuery from "./build-search-query";
import * as actions from "./actions";
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
  notificationSuccess,
  notificationError
} from "containers/page/actions";
import {
  getWorkspaceData,
  deleteStory,
  saveFilters,
  updateSavedFilter,
  translateStory,
  cloneStory,
  getStoryTranslations,
  StoryTranslation
} from "helpers/api";
import { modifySavedFilter, modifyForApi } from "./filter-conversion";
import { navigateFn } from "../../utils/routes.utils";
import { WORKSPACE_TABS_PATH } from "./routes";
import pDebounce from "p-debounce";
import { t } from "i18n";
import { AiStoryTranslationConfig, PartialAppState } from "api/workspace";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import copyToClipboard from "helpers/copy-to-clipboard";
import { get } from "lodash";

const debouncedGetData: any = pDebounce(getWorkspaceData, 250);

export const setSavedFilterAction = (filter: any, modifiedFilter: any) => (dispatch: any) => {
  dispatch({ type: actions.WORKSPACE_SET_SELECTED_SAVED_FILTER, payload: { filter } });

  const filterSet = modifiedFilter.filter;
  Object.keys(filterSet).forEach((key) => {
    let value = filterSet[key];
    dispatch({ type: actions.WORKSPACE_UPDATE_STAGING_FILTER, payload: { key, value } });
  });
};

export const fetchWorkspaceData: any = (selectedTabSlug: any, defaultFilter: any) => (dispatch: any, getState: any) => {
  const modifiedDefaultFilter = defaultFilter ? modifySavedFilter(defaultFilter) : defaultFilter;

  if (modifiedDefaultFilter) {
    dispatch(setSavedFilterAction(defaultFilter, modifiedDefaultFilter));
    dispatch({ type: actions.WORKSPACE_REPLACE_FILTER, payload: { filterSet: modifiedDefaultFilter.filter } });
  }

  const filterSet = (modifiedDefaultFilter && modifiedDefaultFilter.filter) || getState().workspace.currentFilterSet;
  dispatch({ type: actions.WORKSPACE_FETCH_DATA, payload: { filterSet } });
  const query = buildSearchQuery({ ...filterSet, selectedTabSlug });
  getWorkspaceData(query).then(
    (data) => dispatch({ type: actions.WORKSPACE_DATA_SUCCESS, payload: { data } }),
    () => {
      dispatch({ type: actions.WORKSPACE_ADD_ERRORS, payload: "fetchingStories" });
    }
  );
};

export const fetchWorkspaceSearchData = (selectedTabSlug: any, defaultFilter: any) => (
  dispatch: any,
  getState: any
) => {
  const modifiedDefaultFilter = defaultFilter ? modifySavedFilter(defaultFilter) : defaultFilter;

  if (modifiedDefaultFilter) {
    dispatch(setSavedFilterAction(defaultFilter, modifiedDefaultFilter));
    dispatch({ type: actions.WORKSPACE_REPLACE_FILTER, payload: { filterSet: modifiedDefaultFilter.filter } });
  }

  const filterSet = (modifiedDefaultFilter && modifiedDefaultFilter.filter) || getState().workspace.currentFilterSet;
  dispatch({ type: actions.WORKSPACE_FETCH_DATA, payload: { filterSet } });
  const query = buildSearchQuery({ ...filterSet, selectedTabSlug });
  debouncedGetData(query).then(
    (data: any) => dispatch({ type: actions.WORKSPACE_DATA_SUCCESS, payload: { data } }),
    () => {
      dispatch({ type: actions.WORKSPACE_ADD_ERRORS, payload: "fetchingStories" });
    }
  );
};

export const deleteWorkspaceStory = (storyId: any, selectedTabSlug: any, onClose: any) => (dispatch: any) => {
  dispatch({ type: actions.WORKSPACE_DELETE_STORY });
  deleteStory(storyId)
    .then(() => onClose(selectedTabSlug))
    .then(() => dispatch(fetchWorkspaceData(selectedTabSlug)));
};

export const saveFiltersAction = (filterSet: any, filterName: any, tabSlug: any) => (dispatch: any) => {
  const navigate = navigateFn(dispatch);
  const filterForApi = modifyForApi({ filter: filterSet });

  dispatch({ type: actions.WORKSPACE_FILTER_SAVE });
  saveFilters(filterForApi.filter, filterName).then(
    (filter) => {
      dispatch({ type: actions.WORKSPACE_SET_SELECTED_SAVED_FILTER, payload: { filter } });
      navigate(WORKSPACE_TABS_PATH, { tabSlug });
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: { message: t("workspace.filter-save-success") }
      });
    },
    () => {
      dispatch({ type: actions.WORKSPACE_REMOVE_LOADER });
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: {
          message: t("workspace.filter-save-failed"),
          action: {
            label: "Retry",
            callback: () => dispatch(saveFiltersAction(filterSet, filterName, tabSlug))
          }
        }
      });
    }
  );
};

export const updateFilterAction = (savedFilter: any, filterSet: any) => (dispatch: any) => {
  const filter = { ...savedFilter, filter: filterSet };
  const filterForApi = modifyForApi(filter);

  dispatch({ type: actions.WORKSPACE_UPDATE_SELECTED_SAVED_FILTER });
  updateSavedFilter(savedFilter.id, filterForApi);

  dispatch({ type: actions.WORKSPACE_SET_SELECTED_SAVED_FILTER, payload: { filter: filterForApi } });
};

export const updatePageReferer = (referer: any) => (dispatch: any) => {
  dispatch({ type: actions.WORKSPACE_UPDATE_PAGE_REFERER, payload: { referer } });
};

export const translateStoryAction = (storyId: string, storyTranslationConfig: AiStoryTranslationConfig) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
): Promise<void> => {
  try {
    dispatch(notificationSuccess(t("workspace.translation.messages.initiated")));
    const response = await translateStory(storyId, storyTranslationConfig);
    const translatedStoryEditorUrl = response["editor-url"];
    copyToClipboard(translatedStoryEditorUrl);
    dispatch(notificationSuccess(t("workspace.translation.messages.success")));
    window.open(translatedStoryEditorUrl, "_blank");
  } catch (_error) {
    dispatch(notificationError(t("workspace.translation.messages.failure")));
  }
};

export const getStoryTranslationsAction = (storyId: string, translationConfig: AiStoryTranslationConfig) => async (
  dispatch,
  getState
): Promise<{ translations: StoryTranslation[]; error?: string }> => {
  try {
    const result = await getStoryTranslations(
      storyId,
      translationConfig["source-language"],
      translationConfig["target-language"],
      get(translationConfig, ["target-publisher", "id"], getState().config.publisher.id)
    );
    return { translations: result.translations };
  } catch (_error) {
    dispatch(notificationError(t("workspace.translation.messages.failure")));
    return { translations: [], error: "Failed to get translations" };
  }
};

export const cloneStoryAction = (storyId: string) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
): Promise<void> => {
  try {
    dispatch(notificationSuccess(t("workspace.clone.messages.initiated")));
    const response = await cloneStory(storyId);
    const clonedStoryEditorUrl = response["editor-url"];
    copyToClipboard(clonedStoryEditorUrl);
    dispatch(notificationSuccess(t("workspace.clone.messages.success")));
    window.open(clonedStoryEditorUrl, "_blank");
  } catch (_error) {
    dispatch(notificationError(t("workspace.clone.messages.failure")));
  }
};
