/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps } from "react-router";

import Button from "components/button/button";
import {
  loadPushNotification,
  loadPushNotifications,
  initNewPushNotification,
  cancelPublish,
  maybePublish,
  navigateBackFromInspector,
  savePushNotification,
  updatePushNotification,
  updateTab,
  updateSearchTerm,
  savePushNotificationFailure,
  navigateToTab
} from "./async-action-creators";
import Loader from "./loader";
import { LoaderState } from "behaviors/loader/state";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import { navigate } from "utils/routes.utils";
import { PartialAppState } from "./state";
import PushNotificationList from "./components/push-notification-list/push-notification-list";
import PushNotificationForm from "./components/push-notification-form/push-notification-form";
import {
  PUSH_NOTIFICATION_NEW_PATH,
  PUSH_NOTIFICATION_EDIT_PATH,
  PUSH_NOTIFICATION_NEW_PUBLISH_PATH,
  PUSH_NOTIFICATION_EDIT_PUBLISH_PATH,
  PUSH_NOTIFICATION_SCHEDULED_PATH,
  PUSH_NOTIFICATION_PUBLISHED_PATH,
  PUSH_NOTIFICATION_ALL_PATH
} from "./routes";
import PushNotificationHeader from "./components/header/header";
import Inspector from "./components/inspector/inspector";
import wrapPage from "containers/page/page";
import { t } from "i18n";

import styles from "./push-notification.module.css";
import { PushNotificationId } from "api/primitive-types";
import { AnyPushNotification, UnsavedPushNotification } from "api/push-notification";
import { updateCurrentPushNotification } from "./action-creators";
import TAB_LIST, { TabValue, getStringTabValue } from "./tab-list";
import { ScrollTabList, Panel } from "components/tabs/tabs";
import { Aggregations } from "api/workspace";
import SearchBar from "components/search-bar/search-bar";
import { setBodyOverflow } from "utils/dom.utils";
import { LocationState } from "history";

interface StateProps {
  mainLoader: LoaderState;
  saveLoader: LoaderState;
  detailsLoader: LoaderState;
  currentPushNotification: AnyPushNotification | null;
  isInspectorActive: boolean;
  currentTab: TabValue;
  term: string;
  aggregations: Aggregations;
  channelsEnabled?: boolean;
}

interface OwnProps {
  location?: LocationState;
}

interface DispatchProps {
  loadPushNotifications: (term: string, currentTab: TabValue) => void;
  initNewPushNotification: (currentPushNotification: AnyPushNotification | null) => void;
  maybePublish: () => void;
  cancelPublish: () => void;
  addNewPushNotification: () => void;
  loadPushNotification: (currentPushNotification: AnyPushNotification | null, id: PushNotificationId) => void;
  navigateBackFromInspector: (currentPushNotification: AnyPushNotification) => void;
  saveNewPushNotification: (
    publishAt: number | null,
    pushNotification: AnyPushNotification,
    channelsEnabled: boolean
  ) => void;
  updatePushNotification: (
    publishAt: number | null,
    pushNotification: AnyPushNotification,
    channelsEnabled: boolean
  ) => void;
  updateCurrentPushNotification: (pushNotification: UnsavedPushNotification) => void;
  updateTab: (tab: TabValue) => void;
  saveNewPushNotificationFailure: (error: Error) => void;
  updateSearchTerm: (term: string, tab: TabValue) => void;
  navigateToTab: (tabValue: TabValue) => void;
}

type Props = RouteComponentProps<any> &
  StateProps &
  OwnProps &
  DispatchProps & {
    title: string;
    isBannerPresent: boolean;
  };

export const PushNotificationFormWrapper: React.SFC<Props> = ({
  saveLoader,
  detailsLoader,
  currentPushNotification,
  isInspectorActive,
  navigateBackFromInspector,
  saveNewPushNotification,
  updateCurrentPushNotification,
  updatePushNotification,
  saveNewPushNotificationFailure,
  channelsEnabled,
  isBannerPresent,
  location
}) => (
  <React.Fragment>
    <section className={styles["page-container push-notification-page"]} data-test-id="push-notification-page">
      <LoaderWrapper className={"push-notification-progress-main-area"} component={Loader} loader={saveLoader}>
        <h3 className={styles["push-notification-page-title"]}>{t("push-notification.title")}</h3>
        <PushNotificationForm location={location} />
        {currentPushNotification && (
          <Inspector
            error={detailsLoader.error}
            currentPushNotification={currentPushNotification}
            isInspectorActive={isInspectorActive}
            navigateBackFromInspector={navigateBackFromInspector}
            saveNewPushNotification={saveNewPushNotification}
            updatePushNotification={updatePushNotification}
            updateCurrentPushNotification={updateCurrentPushNotification}
            saveNewPushNotificationFailure={saveNewPushNotificationFailure}
            channelsEnabled={channelsEnabled}
          />
        )}
      </LoaderWrapper>
    </section>
  </React.Fragment>
);

export const PushNotificationListContainer: React.SFC<Props> = ({
  currentTab,
  mainLoader,
  addNewPushNotification,
  navigateToTab,
  term,
  updateSearchTerm,
  aggregations,
  isBannerPresent
}) => {
  const tabs = TAB_LIST(t).map((tab) => ({
    ...tab,
    badge: getBadgeCount(tab.value, aggregations)
  }));

  return (
    <div className={styles["push-notification-list-container"]} data-test-id="push-notification-list-container">
      <header className={styles["push-notification-header"]} data-test-id="push-notification-header">
        <div className={styles["push-notification-search"]}>
          <SearchBar
            value={term}
            onChange={(changedTerm) => updateSearchTerm(changedTerm, currentTab)}
            placeholder={t("push-notification.search_placeholder")}
          />
        </div>
        <div className={styles["push-notification-action-button"]}>
          <Button
            type="secondary"
            onClick={() => {
              addNewPushNotification();
            }}>
            {t("push-notification.cta.create")}
          </Button>
        </div>
      </header>
      <ScrollTabList
        tabs={tabs}
        value={currentTab}
        onChange={(tabValue: TabValue) => {
          navigateToTab(tabValue);
        }}
      />
      <Panel>
        <LoaderWrapper className={"push-notification-progress-main-area"} component={Loader} loader={mainLoader}>
          <PushNotificationList />
        </LoaderWrapper>
      </Panel>
    </div>
  );
};

export const getBadgeCount = (tabValue: TabValue, aggregations: Aggregations): number => {
  return aggregations.status[getStringTabValue(tabValue)];
};

const mapStateToProps = (state: PartialAppState): StateProps & { title: string } => {
  return {
    mainLoader: state.pushNotification.ui.main,
    saveLoader: state.pushNotification.ui.save,
    detailsLoader: state.pushNotification.ui.details,
    currentPushNotification: state.pushNotification.app.currentPushNotification,
    isInspectorActive: state.pushNotification.ui.isInspectorActive,
    currentTab: state.pushNotification.ui.currentTab,
    term: state.pushNotification.ui.searchTerm,
    aggregations: state.pushNotification.aggregations,
    channelsEnabled: state.features.enablePushNotificationChannels,
    title: "push_notifications"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadPushNotifications: (term: string, tabValue: TabValue) => dispatch(loadPushNotifications(term, tabValue)),
    initNewPushNotification: (currentPushNotification: AnyPushNotification | null) =>
      dispatch(initNewPushNotification(currentPushNotification)),
    maybePublish: () => dispatch(maybePublish()),
    cancelPublish: () => dispatch(cancelPublish()),
    addNewPushNotification: () => dispatch(navigate(PUSH_NOTIFICATION_NEW_PATH)),
    loadPushNotification: (currentPushNotification, id) => dispatch(loadPushNotification(currentPushNotification, id)),
    navigateBackFromInspector: (currentPushNotification: AnyPushNotification) =>
      dispatch(navigateBackFromInspector(currentPushNotification)),
    saveNewPushNotification: (
      publishAt: number | null,
      pushNotification: AnyPushNotification,
      channelsEnabled: boolean
    ) => dispatch(savePushNotification(publishAt, pushNotification, channelsEnabled)),
    updateCurrentPushNotification: (pushNotification: UnsavedPushNotification) =>
      dispatch(updateCurrentPushNotification(pushNotification)),
    updatePushNotification: (
      publishAt: number | null,
      pushNotification: AnyPushNotification,
      channelsEnabled: boolean
    ) => dispatch(updatePushNotification(publishAt, pushNotification, channelsEnabled)),
    updateTab: (tab: TabValue) => dispatch(updateTab(tab)),
    updateSearchTerm: (term: string, tab: TabValue) => dispatch(updateSearchTerm(term, tab)),
    saveNewPushNotificationFailure: (error: Error) => dispatch(savePushNotificationFailure(error)),
    navigateToTab: (tabValue: TabValue) => dispatch(navigateToTab(tabValue))
  };
};

export class PushNotificationWithRoutes extends React.Component<Props> {
  async componentDidMount() {
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case PUSH_NOTIFICATION_SCHEDULED_PATH:
        setBodyOverflow("auto");
        this.props.updateTab(TabValue.SCHEDULED);
        this.props.loadPushNotifications("", TabValue.SCHEDULED);
        break;

      case PUSH_NOTIFICATION_ALL_PATH:
        setBodyOverflow("auto");
        this.props.updateTab(TabValue.ALL);
        this.props.loadPushNotifications("", TabValue.ALL);
        break;

      case PUSH_NOTIFICATION_PUBLISHED_PATH:
        setBodyOverflow("auto");
        // setBodyOverflow("auto"); --> This is an ugly way to reset scroll on body after publish. Inspector is not closed after publish so overflow from body is not removed. To fix this properly, refactor PushNotifications to render inspector even on listing page.
        this.props.updateTab(TabValue.PUBLISHED);
        this.props.loadPushNotifications("", TabValue.PUBLISHED);

        break;

      case PUSH_NOTIFICATION_NEW_PATH:
        this.props.initNewPushNotification(this.props.currentPushNotification);
        this.props.cancelPublish();
        break;

      case PUSH_NOTIFICATION_NEW_PUBLISH_PATH:
        this.props.initNewPushNotification(this.props.currentPushNotification);
        this.props.maybePublish();
        break;

      case PUSH_NOTIFICATION_EDIT_PATH:
        this.props.loadPushNotification(this.props.currentPushNotification, this.props.match.params.id);
        this.props.cancelPublish();
        break;

      case PUSH_NOTIFICATION_EDIT_PUBLISH_PATH:
        this.props.loadPushNotification(this.props.currentPushNotification, this.props.match.params.id);
        this.props.maybePublish();
        break;

      default:
        break;
    }
  }

  render() {
    switch (this.props.match.path) {
      case PUSH_NOTIFICATION_SCHEDULED_PATH:
        return <PushNotificationListContainer {...this.props} />;
      case PUSH_NOTIFICATION_PUBLISHED_PATH:
        return <PushNotificationListContainer {...this.props} />;
      case PUSH_NOTIFICATION_ALL_PATH:
        return <PushNotificationListContainer {...this.props} />;

      case PUSH_NOTIFICATION_NEW_PATH:
        return <PushNotificationFormWrapper {...this.props} />;

      case PUSH_NOTIFICATION_EDIT_PATH:
        return <PushNotificationFormWrapper {...this.props} />;

      default:
        return <PushNotificationFormWrapper {...this.props} />;
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({
    HeaderComponent: PushNotificationHeader,
    isStoryPage: false
  })
)(PushNotificationWithRoutes);
