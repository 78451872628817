/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";

import Button from "../../../../components/button/button";
import { COLLECTIONS_PATH } from "../../routes";
import { t } from "i18n";
import { maybePublishAction, publishCollectionAction } from "pages/collections/async-action-creators";
import Header from "components/header/header";

class CollectionsHeader extends React.Component<any, any> {
  render() {
    const { hasCollectionEditPermission, location } = this.props;
    const path = location && location.pathname.replace(/\/$/, "");
    //removes trailing slash
    const shouldShowPublishButton = path !== COLLECTIONS_PATH && hasCollectionEditPermission;

    return (
      <Header>
        {shouldShowPublishButton && (
          <Button
            type="primary"
            variant="success"
            disabled={!this.props.isCollectionModified}
            onClick={() => {
              this.props.collectionId === "new" || this.props.status === "scheduled"
                ? this.props.maybePublish(this.props.collectionId)
                : this.props.publishCollection();
            }}>
            {t("collections.publish")}
          </Button>
        )}
      </Header>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    collectionId:
      state.collections.collectionPage.selectedCollection && state.collections.collectionPage.selectedCollection.id
        ? state.collections.collectionPage.selectedCollection.id
        : "new",
    status:
      state.collections.collectionPage.selectedCollection && state.collections.collectionPage.selectedCollection.status,
    hasCollectionEditPermission: state.features.hasCollectionEditPermission,
    location: ownProps.location,
    isCollectionModified: state.collections.ui.isCollectionModified
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    publishCollection: () => dispatch(publishCollectionAction()),
    maybePublish: (collectionId) => dispatch(maybePublishAction(collectionId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsHeader);
