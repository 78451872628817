/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";

import { Router as WorkspaceRouter } from "./pages/workspace/router";
import { Router as MediaLibraryRouter } from "./pages/media-library/router";
import { Router as ManageRouter } from "./pages/manage/router";
import { Router as StoryEditorRouter } from "./pages/story-editor/router";
import { Router as AdvancedSettingsRouter } from "./pages/advanced-settings/router";
import { Router as SettingsRouter } from "./pages/settings/router";
import { Router as ConsumersRouter } from "./pages/consumers-dashboard/router";
import { Router as BillingRouter } from "./pages/billing/router";
import { Router as ContentRouter } from "./pages/content/router";
import { Router as UserRouter } from "./pages/user/router";
import { Router as LoginRouter } from "./pages/login/router";
import { Router as BreakingNewsRouter } from "./pages/breaking-news/router";
import { Router as AnalyticsRouter } from "./pages/analytics/router";
import { Router as SecretModeRouter } from "./pages/secret-mode/router";

const Router = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/workspace" />
      <Redirect exact from="/workspace" to="/workspace/all" />
      <Redirect exact from="/user" to="/user/profile" />
      <Redirect exact from="/push-notification/new" to="/content/push-notifications/new" />
      <Redirect exact from="/settings" to="/settings/general" />
      <Redirect exact from="/analytics" to="/analytics/story/view/day" />
      <Route path="/billing" component={BillingRouter} />
      <Route path="/workspace" component={WorkspaceRouter} />
      <Route path="/media-library" component={MediaLibraryRouter} />
      <Route path="/manage" component={ManageRouter} />
      <Route path="/story" component={StoryEditorRouter} />
      <Route path="/advanced-settings" component={AdvancedSettingsRouter} />
      <Route path="/settings" component={SettingsRouter} />
      <Route path="/consumers" component={ConsumersRouter} />
      <Route path="/content" component={ContentRouter} />
      <Route path="/user" component={UserRouter} />
      <Route path="/login" component={LoginRouter} />
      <Route path="/forgot-password" component={LoginRouter} />
      <Route path="/auth/reset-password" component={LoginRouter} />
      <Route path="/breaking-news" component={BreakingNewsRouter} />
      <Route path="/setup-two-factor-auth" component={LoginRouter} />
      <Route path="/validate-two-factor-auth" component={LoginRouter} />
      <Route path="/analytics" component={AnalyticsRouter} />
      <Route path="/admin" component={SecretModeRouter} />
    </Switch>
  );
};

export default Router;
